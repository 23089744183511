import Context = require("Everlaw/Context/Context");
import { Context as DatabaseContext } from "Everlaw/Database";
import { CreateRequestDemoForm } from "Everlaw/MarketoForm";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import ContextSideBar = require("Everlaw/Context/UI/SideBar");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Server = require("Everlaw/Server");
import SideBar = require("Everlaw/UI/SideBar");

const SbFree = new (class extends Context {
    constructor() {
        super(DatabaseContext.SBFREE);
    }
    override inServerContext() {
        return Server.isSbFree();
    }
    override SideBar(params: SbFree.SideBarParams) {
        if (!SbFree.inContext()) {
            return super.SideBar(params);
        }
        params.inaccessibleNodes = params.inaccessibleNodes || {};
        (params.limitedFeatureNodes || []).forEach((id) => {
            const requestDemoModalComponent = wrapReactComponent(CreateRequestDemoForm, {
                isButton: false,
                isModalVisible: false,
                onModalClose: () => {
                    requestDemoModalComponent.updateProps({ isModalVisible: false });
                },
            });
            params.inaccessibleNodes[id] = {
                onClick: () => {
                    requestDemoModalComponent.updateProps({ isModalVisible: true });
                },
                updateNode: (node) => {
                    const iconClass = `crown-filled-yellow${node instanceof SideBar.SBChild ? "-20" : ""}`;
                    // Placing as the first element prevents spacing errors for long side bar
                    // node names.
                    Dom.place(new Icon(iconClass, {}), node.element, 0);
                },
            };
        });
        return super.SideBar(params);
    }
})();

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module SbFree {
    export interface SideBarParams extends ContextSideBar.Params {
        limitedFeatureNodes?: string[];
    }
}

export = SbFree;
